import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../../components/Seo';
import {
  getArticles,
  getPrograms,
  getDefaultSEO,
  getFeaturedImageDataFromWpPost,
} from '../../utils';
import EarnAndUsePoints from '../../components/EarnAndUsePoints/EarnAndUsePoints';
import {
  AIRLINES_CATEGORIES,
  CREDIT_CARDS_CATEGORIES,
  HOTEL_CATEGORIES,
  HT_PAGE_CATEGORY_HUB_PAGE,
} from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IEarnAndUsePointsPageProps {
  getStarted: Queries.WpPostConnection;
  creditCardPointsPrograms: Queries.WpPostConnection;
  airlineMilesPrograms: Queries.WpPostConnection;
  hotelPointsPrograms: Queries.WpPostConnection;
}

const EarnAndUsePointsPage = ({ data, location }: PageProps<IEarnAndUsePointsPageProps>) => {
  const { airlineMilesPrograms, getStarted, hotelPointsPrograms, creditCardPointsPrograms } = data;
  let airlineMilesProgramsPosts: Queries.WpPost[] = [];
  let creditCardPointsProgramsPosts: Queries.WpPost[] = [];
  let getStartedPosts: Queries.WpPost[] = [];
  let hotelPointsProgramsPosts: Queries.WpPost[] = [];

  if (airlineMilesPrograms && Array.isArray(airlineMilesPrograms.nodes)) {
    airlineMilesProgramsPosts = airlineMilesPrograms.nodes;
  }

  if (creditCardPointsPrograms && Array.isArray(creditCardPointsPrograms.nodes)) {
    creditCardPointsProgramsPosts = creditCardPointsPrograms.nodes;
  }

  if (getStarted && Array.isArray(getStarted.nodes)) {
    getStartedPosts = getStarted.nodes;
  }

  if (hotelPointsPrograms && Array.isArray(hotelPointsPrograms.nodes)) {
    hotelPointsProgramsPosts = hotelPointsPrograms.nodes;
  }

  const getStartedArticles = getArticles(getStartedPosts).map((article) => ({
    ...article,
    imageOverlay: true,
    category: {
      text: 'Guide',
    },
  }));
  const creditCardPointsProgramsArticles = getPrograms(creditCardPointsProgramsPosts).map(
    (program) => {
      const category = CREDIT_CARDS_CATEGORIES.find((category) =>
        category.options.some((option) =>
          program.title.toLowerCase().includes(option.toLowerCase()),
        ),
      );
      if (category) {
        return {
          ...program,
          category,
        };
      }
      return program;
    },
  );
  const airlineMilesProgramsArticles = getPrograms(airlineMilesProgramsPosts).map((program) => {
    const category = AIRLINES_CATEGORIES.find((category) =>
      category.options.some((option) => program.title.toLowerCase().includes(option.toLowerCase())),
    );
    if (category) {
      return {
        ...program,
        category,
      };
    }
    return program;
  });
  const hotelPointsProgramsArticles = getPrograms(hotelPointsProgramsPosts).map((program) => {
    const category = HOTEL_CATEGORIES.find((category) =>
      category.options.some((option) => program.title.toLowerCase().includes(option.toLowerCase())),
    );
    if (category) {
      return {
        ...program,
        category,
      };
    }
    return program;
  });
  const title = 'Earn & Use Points';

  return (
    <HTPageWrapper category={HT_PAGE_CATEGORY_HUB_PAGE} title={title} location={location}>
      <EarnAndUsePoints
        pageHeader={{
          title: title,
          subTitle:
            'Explore the different credit card points programs and learn how to maximize your credit card points.',
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
          ],
        }}
        getStarted={{
          articles: getStartedArticles,
          subTitle:
            "If you're new to travel or credit card rewards then you've come to the right place. Below you'll find our popular guides to get you started.",
          title: 'Get Started',
        }}
        creditCardPointsPrograms={{
          programs: creditCardPointsProgramsArticles,
          isScrollable: true,
          moreLink: {
            link: '/earn-use-points/credit-cards/',
            text: 'VIEW ALL POINTS PROGRAMS',
          },
          title: 'Credit Card Points Programs',
          subTitle:
            'Learn the different ways to earn and redeem points from your credit card points program, with detailed guides from our industry experts.',
        }}
        airlineMilesPrograms={{
          programs: airlineMilesProgramsArticles,
          isScrollable: true,
          moreLink: {
            link: '/earn-use-points/airlines/',
            text: 'VIEW ALL AIRLINE MILES PROGRAMS',
          },
          title: 'Airline Miles Programs',
          subTitle:
            'Learn how to always travel in style, with our detailed expert guides on earning and using airline miles.',
        }}
        hotelPointsProgram={{
          programs: hotelPointsProgramsArticles,
          isScrollable: true,
          moreLink: {
            link: '/earn-use-points/hotels/',
            text: 'VIEW ALL HOTEL POINTS PROGRAMS',
          },
          title: 'Hotel Points Programs',
          subTitle:
            'Browse our expert guides on earning and spending hotel points, to upgrade your next getaway.',
        }}
      />
    </HTPageWrapper>
  );
};

export default EarnAndUsePointsPage;

export const Head = ({ location, data }: PageProps<IEarnAndUsePointsPageProps>) => {
  const pageTitle = 'Earn & Use Credit Card, Hotel and Airline Points';
  const socialTitle = pageTitle;
  const ogDescription =
    'Find the best points programs for you and learn how to earn more points and how to get the most out of them.';
  const includeCollectionPageType = true;
  const extraBreadcrumbList = [{ name: 'Earn & Use Points', slug: 'earn-use-points' }];
  const featuredPost = data?.getStarted?.nodes[0];
  const featuredImageData = featuredPost ? getFeaturedImageDataFromWpPost(featuredPost) : null;
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  return <Seo title={pageTitle} wpSeo={seo} featuredImageData={featuredImageData} />;
};

export const query = graphql`
  query EarnAndUsePointsQuery {
    getStarted: allWpPost(filter: { databaseId: { in: [35789, 71437, 138022] } }) {
      nodes {
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
        title
        uri
      }
    }
    creditCardPointsPrograms: allWpPost(
      filter: { databaseId: { in: [33129, 5810, 80280, 348, 258415, 5584, 79990, 5775, 136204] } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    airlineMilesPrograms: allWpPost(
      filter: { databaseId: { in: [25264, 3424, 3016, 28601, 3441, 3294, 3708, 16712, 3024] } }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
    hotelPointsPrograms: allWpPost(
      filter: {
        databaseId: { in: [152714, 152716, 18523, 128737, 147030, 7198, 237063, 3071, 19800] }
      }
    ) {
      nodes {
        featuredImage {
          node {
            sourceUrl
          }
        }
        title
        uri
      }
    }
  }
`;
