import React, { FC } from 'react';
import { IEarnAndUsePoints } from './EarnAndUsePoints.def';
import PageHeader from '../PageHeader/PageHeader';
import SquareBrowser from '../SquareBrowser/SquareBrowser';
import Programs from '../Programs/Programs';

const EarnAndUsePoints: FC<IEarnAndUsePoints> = ({
  getStarted,
  pageHeader,
  creditCardPointsPrograms,
  hotelPointsProgram,
  airlineMilesPrograms,
}) => {
  return (
    <div className="earn-and-use-points">
      <PageHeader {...pageHeader} />
      <SquareBrowser {...getStarted} />
      <div className="container">
        <Programs {...creditCardPointsPrograms} />
        <Programs {...airlineMilesPrograms} />
        <Programs {...hotelPointsProgram} />
      </div>
    </div>
  );
};

export default EarnAndUsePoints;
